import React, { useState } from 'react';
import { Link, navigate, PageRenderer } from 'gatsby'
import agent from "../../lib/agent"
import Modal from "react-modal";

Modal.setAppElement(`#___gatsby`);

const Login = () => {

  let [account, setAccount] = useState({
    email: '',
    password: '',
  });
  let [spinner, setSpinner] = useState({
    class: 'hidden',
    disabled: false,
  });
  let [error, setError] = useState("")
  
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const a = {...account}
    a[name] = value;
    setAccount(a);
  }

  const submit = (e) => {
    setSpinner({
      class: "",
      disabled: true
    })
    setError("")
    e.preventDefault();
    console.log(account);
    agent.Account.login(account)
      .then(response => {
        console.log(response)
        if (response.data.token) {
          agent.SetTokens(response.data.token)
          window.location.href = "/app";
        }
      })
      .catch(err => {
        console.log(err)
        setError("error")
      })
      .then(() => {
        setSpinner({
          class: "hidden",
          disabled: false
        })
      })
  }

  const googleLogin = () => {
    window.location.href = "/api/v1/oauth/google?action=login";
  }
/*
  const oktaLogin = () => {
    window.location.href = "/api/v1/oauth/okta?action=login";
  }
*/
  const hideClass = error.length ? "": "d-none"

  const [modalOpen, setModalOpen] = useState(true);
  const modalCloseTimeout = 300;
  const closeModal = () => {
    setModalOpen(false);
    setTimeout(() => navigate(`/`), modalCloseTimeout);
  };

  return (
    <>
      <PageRenderer key={"/"} location={{ pathname: "/" }} />
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        closeTimeoutMS={modalCloseTimeout}
      >
        <div id="login">
          <Link
            to="/"
            aria-label="close modal"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            className="close-background"></Link>
            <div className="form-card-container">
                <Link to="/" className="form-card-close"></Link>
                <h3 className="form-card-header">Log in</h3>
                <div className="form-card-content">
                    <div className="col-lg-12">
                        <div className="form-card-form">
                            <div className={`error ${hideClass} p-3 m-3`}>
                              <p className="text-danger text-center mb-3">
                                Error occurred while logging in, please check your email and password
                              </p>
                            </div>
                            <form id="login-submit" action="" onSubmit={submit}>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <fieldset>
                                    <input name="email" type="email" className="form-control email" placeholder="Email" required="" autoComplete="username" onChange={handleChange}/>
                                  </fieldset>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <fieldset>
                                    <input name="password" type="password" className="form-control password" placeholder="Password" required="" autoComplete="current-password" onChange={handleChange}/>
                                  </fieldset>
                                </div>

                                <div className="col-lg-12">
                                  <fieldset>
                                    <button type="submit" className="form-submit main-button col-lg-12" aria-label="Login">
                                      <span className={`${spinner.class} spinner-grow spinner-grow-sm`} disabled={spinner.disabled} role="status" aria-hidden="true"></span>
                                      Log In
                                  </button>
                                  </fieldset>
                                </div>

                                <div className="or-line col-lg-12">or</div>

                                <div className="col-lg-12">
                                  <fieldset>
                                    <button type="button" id="google-login" className="main-button secondary-button col-lg-12" aria-label="Google Login" onClick={googleLogin}><svg width="18" height="18"><path d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z" fill="#4285F4"></path><path d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z" fill="#34A853"></path><path d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z" fill="#FBBC05"></path><path d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z" fill="#EA4335"></path></svg> Log In with Google</button>
                                  </fieldset>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 link-row mt-4">
                                    <div className="row">
                                        <div className="left col-lg-6 col-md-6 col-sm-6 col-6">
                                            <Link to="/forgot-password" className="track-forgot-password">Forgot password?</Link>
                                        </div>
                                        <div className="right col-lg-6 col-md-6 col-sm-6 col-6">
                                            <Link to="/signup" className="track-open-signup" data-location="login-form">Sign up</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Modal>
    </>
  )
}
/*
const OktaLogin = () => {
  return (
    <div className="col-lg-12 mt-4" >
      <fieldset>
        <button type="button" id="okta-login" className="main-button secondary-button col-lg-12" aria-label="Okta Login"><svg width="24" height="24" className="mr-1"><path d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm10 6c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6z"></path></svg> Log In with Okta</button>
      </fieldset>
    </div>
  )
}
*/

export default Login